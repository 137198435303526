import React, { useRef, useEffect, useContext, useState } from 'react'
import { Box, Text, Heading } from 'theme-ui'

export default function Video({ src, poster, sx }) {
  const videoRef = useRef(null)

  useEffect(() => {
    const { current: videoElement } = videoRef
    videoElement.setAttribute('muted', '')
    videoElement.setAttribute('webkit-playsinline', '')

    return () => {}
  }, [])

  return (
    <Box
      as='video'
      sx={{ ...styles.video, ...sx }}
      autoPlay={true}
      muted={true}
      playsInline
      loop={true}
      ref={videoRef}
      poster={poster}
      alt='coverposter'
    >
      <source src={src} type='video/mp4' />
      <track kind='captions' />
    </Box>
  )
}

const styles = {
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}
