import React, { useRef, useEffect, useContext, useState } from 'react'
import { MdRoomService, MdRestaurant } from 'react-icons/md'
import { GiPartyPopper } from 'react-icons/gi'
import { Box, Image, Text } from 'theme-ui'
import { store } from '../../context/store'
import Logo from '../UI/Logo'
import { Link } from 'gatsby'
import shortid from 'shortid'
import storySelector from '../../helpers/storySelector'

export default function Hero({ storyId, buttons, logoHidden }) {
  const { globalState, dispatch } = useContext(store)
  const { stories } = globalState
  const [displayedSlideIndex, setDisplayedSlideIndex] = useState(false)

  const { title, subtitle, body, images } = storySelector(storyId, stories)

  // const videoRef = useRef(null)

  // useEffect(() => {
  //   const { current: videoElement } = videoRef
  //   videoElement.setAttribute('muted', '')
  //   videoElement.setAttribute('webkit-playsinline', '')

  //   return () => {}
  // }, [])

  const renderButtons = () => {
    return buttons.map((btn, index) => {
      return (
        <Link
          key={`${btn.name + index}`}
          to={btn.link}
          sx={styles.box}
          onMouseEnter={(e) => setDisplayedSlideIndex(index)}
          onMouseLeave={(e) => setDisplayedSlideIndex(false)}
        >
          {btn.name}
        </Link>
      )
    })
  }

  const renderImages = () =>
    images.map((image, index) => {
      const backgroundImageStyles =
        displayedSlideIndex === index
          ? {
              opacity: '1',
              transform: 'scale(1.1)',
            }
          : {}
      return (
        <Image
          key={`heroimage${index}`}
          sx={{ ...styles.buttonImage, ...backgroundImageStyles }}
          src={image}
        />
      )
    })

  return (
    <Box sx={styles.container}>
      {/* Video Container */}
      <Box
        as='video'
        sx={styles.video}
        autoPlay={true}
        muted={true}
        playsInline
        loop={true}
        // ref={videoRef}
        poster={images[0]}
        alt='coverposter'
      >
        {/* <source src={video} type='video/mp4' />
        <track kind='captions' /> */}
      </Box>

      {/* <Text sx={styles.heroTagline}>Dine At</Text> */}

      {/* Image Container */}
      <Box sx={{ ...styles.ImageContainer }}>{renderImages()}</Box>

      {/* Cta Container */}
      <Box sx={styles.boxes}>
        <Link to='/' sx={styles.box}>
          {logoHidden ? '' : <Logo sx={styles.logo} />}
        </Link>
        {renderButtons()}
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    height: '100vh',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  heroTagline: {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    zIndex: '300',
    width: '100%',
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    filter: 'brightness(0.6)',
  },
  boxes: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    zIndex: '100',
    width: '100%',
    'a:nth-of-type(1)': {
      border: 'none',
      width: ['100%', '', '32.5%'],
      padding: ['4rem', '', '2rem'],
      transition: 'all ease-in-out 0.5s',
    },
    'a:nth-of-type(2)': {
      borderLeft: ['none', '', 'solid 1px white'],
    },
  },
  box: {
    flexGrow: '1',
    width: '22.5%',
    height: 'fit-content',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeft: 'solid 1px white',
    textTransform: 'capitalize',
    fontWeight: '700',
    fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
    transition: 'all ease-in-out 1s',

    ':hover': {
      // height: '100px',
      transform: 'scale(1.1)',
    },
  },
  logo: {
    maxWidth: ['200px', '300px'],
  },
  ImageContainer: {
    transition: 'all ease-in-out 1s',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: '0',
    top: '0',
  },
  buttonImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    transition: 'all ease-in-out 0.75s',
    left: '0',
    top: '0',
    objectFit: 'cover',
    filter: 'brightness(0.6)',
    opacity: '0',
    display: 'flex',
  },
}

Hero.defaultProps = {
  logoHidden: true,
  // video:
  //   '',
  // images: ['','','' ],
  buttons: [
    {
      name: 'Dine in',
      link: 'menu',
      icon: <MdRoomService />,
    },
    {
      name: 'About Us',
      link: 'about',
      icon: <MdRestaurant />,
    },
    {
      name: 'Celebrate',
      link: 'private-events',
      icon: <GiPartyPopper />,
    },
  ],
}
