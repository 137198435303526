import React, { useContext } from 'react'
import LazyLoader from '../GeneralComponents/LazyLoader'
import { Image } from 'theme-ui'
import checkIfExternalLink from '../../helpers/checkIfExternalLink'
import findPoweredImageURLById from '../../helpers/findPoweredImageURLById'
import { store } from '../../context/store'

export default function ImageComponent({ src, ...rest }) {
  const { globalState, dispatch } = useContext(store)

  const isPoweredImage = !checkIfExternalLink(src)

  const imageSelector = isPoweredImage
    ? findPoweredImageURLById(src, globalState.poweredImages)
    : src

  return (
    <LazyLoader>
      <Image src={imageSelector} {...rest} />
    </LazyLoader>
  )
}

const styles = {
  container: {},
}
