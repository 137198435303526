import React, { useContext } from 'react'
import { Box } from '@theme-ui/components'
import TextContent from '../Text/TextContent'
import shortid from 'shortid'
import { store } from '../../context/store'
import ImagesRenderer from '../Images/ImagesRenderer'
import storySelector from '../../helpers/storySelector'
import Image from '../Images/Image'
import LinkSelector from '../ComponentSelectors/LinkSelector'

export default function ContentBoxes({ storyIds, video, sx, ...rest }) {
  const { globalState, dispatch } = useContext(store)

  const { stories } = globalState

  const renderStories = () => {
    return storyIds.map((storyId) => {
      const { title, subtitle, body, images, buttons } = storySelector(
        storyId,
        stories
      )
      return (
        <LinkSelector to={buttons?.[0]?.link} sx={styles.box}>
          {images?.length > 0 ? (
            <Image src={images[0]} sx={styles.image} />
          ) : (
            ''
          )}
          <TextContent
            title={title}
            subtitle={subtitle}
            sx={styles.textContent}
          />
        </LinkSelector>
      )
    })
  }

  return (
    <Box {...rest} sx={{ ...styles.container, ...sx }}>
      {renderStories()}
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: ['column', 'row'],
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['0rem 1rem', '', '0rem 2rem'],
    '> a:nth-of-type(even)': {
      position: 'relative',
      left: ['-1rem', 'unset'],
    },
    '> a:nth-of-type(odd)': {
      position: 'relative',
      right: ['-1rem', 'unset'],
    },
  },
  box: {
    flexGrow: '1',
    width: ['100%', 'calc(50% - 2rem)', '', 'calc(25% - 2rem)'],
    margin: ['0rem 0rem 1rem', '1rem'],
    transition: 'all ease-in-out 2s',
    '.lazyload-wrapper': {
      overflow: 'hidden',
      marginBottom: '1rem',
    },
    ':hover': {
      opacity: '0.8',
    },
  },

  image: {
    height: ['300px', '375px', '425px'],
    width: '100%',
    objectFit: 'cover',
    transition: 'all ease-in-out 2s',
    display: 'flex',
    ':hover': {
      transform: 'scale(1.2)',
    },
  },
  textContent: {
    padding: ['0.5rem 1rem', '0rem'],
  },
}
