import React, { useContext } from 'react'
import { Box, Image } from '@theme-ui/components'
import TextContent from '../Text/TextContent'
import shortid from 'shortid'
import { store } from '../../context/store'
import ImagesRenderer from '../Images/ImagesRenderer'
import storySelector from '../../helpers/storySelector'
import Video from './Video'

export default function ContentBlockMain({ storyId, video, sx, ...rest }) {
  const { globalState, dispatch } = useContext(store)
  const { stories } = globalState

  const { title, subtitle, body, images, buttons } = storySelector(
    storyId,
    stories
  )

  return (
    <Box {...rest} sx={{ ...styles.container, ...sx }}>
      {/* <ImagesRenderer images={images} sx={styles.imagesContainer} /> */}

      {images.map((image, index) => (
        <Box
          sx={styles.imageContainer}
          className={`imageContainer imageContainer${index + 1}`}
        >
          {video && index === 0 ? (
            <Box sx={styles.imageContainer} className={`videoContainer`}>
              <Video src={video} poster={images[0]} sx={styles.image} />{' '}
            </Box>
          ) : (
            <Image src={image} sx={styles.image} className='image' />
          )}
        </Box>
      ))}

      <TextContent
        sx={styles.textContent}
        className='content'
        title={title}
        subtitle={subtitle}
        text={body}
        buttons={buttons}
      />
    </Box>
  )
}

const styles = {
  container: {
    padding: ['1rem', '', '2rem'],
    display: ['flex', '', ''],
    flexWrap: ['wrap'],
    flexDirection: ['column', '', 'row'],
    overflowX: 'hidden',
    '.videoContainer': {
      display: 'flex',
      width: ['100%', '', '60%'],
      flexGrow: '1',
      order: '1',
    },
    '.imageContainer': {
      // minHeight: '30vh',
      // video: {
      //   width: '100%',
      // },
      maxHeight: '65vh',
      overflow: 'hidden',
      order: '2',
      padding: ['0.5rem 0rem', '', '1rem'],
      flexGrow: '1',
    },
    '.imageContainer1': {
      width: ['100%', '', '60%'],
      display: 'flex',
      alignItems: 'flex-end',
      img: {
        height: '100%',
      },
      position: 'relative',
      left: ['-2rem', '', '0rem'],
    },
    '.imageContainer2': {
      width: ['100%', '', '40%'],
      order: '2',
      display: 'flex',
      alignItems: 'flex-end',
      img: {
        height: '75%',
      },
      position: 'relative',
      right: ['-3rem', '', '0rem'],
    },
    '.imageContainer3': {
      width: ['100vw', '', '60%'],
      order: '4',
      position: 'relative',
      left: ['-1rem', '', '0rem'],
    },
    '.image': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },

  imagesContainer: {},
  textContent: {
    width: ['100%', '', '40%'],
    order: '3',
    padding: ['2rem 1rem', '3rem'],
    '.title': {
      order: '2',
      '::after': {
        display: 'flex',
        content: "''",
        width: '50px',
        height: '1px',
        backgroundColor: 'grey',
        margin: '0.75rem 0rem 1.5rem',
      },
    },
    '.subtitle': {
      order: '1',
    },
    '.body': {
      order: '3',
    },
    '.buttonsContainer': {
      order: '4',
      a: {
        variant: 'buttons.primary',
      },
    },
  },
}

// const styles = {
//   container: {
//     padding: ['1rem', '', '2rem'],
//     display: ['flex', '', 'grid'],
//     flexWrap: 'wrap',
//     gridTemplateColumns: 'repeat(5, 1fr)',
//     gridTemplateRows: 'repeat(3, 1fr)',
//     gridColumnGap: '20px',
//     gridRowGap: '20px',
//     '.imageContainer': {
//       minHeight: '30vh',
//       video: {
//         width: '100%',
//       },
//     },
//     '.imageContainer1': {
//       display: 'grid',
//       gridColumnStart: '1',
//       gridColumnEnd: '4',
//       gridRowStart: '1',
//       gridRowEnd: '2',
//       alignSelf: 'end',
//     },
//     '.imageContainer2': {
//       display: ['none', '', 'grid'],
//       gridColumnStart: '4',
//       gridColumnEnd: '6',
//       gridRowStart: '2',
//       gridRowEnd: '1',
//       alignSelf: 'end',
//     },
//     '.imageContainer3': {
//       display: 'grid',
//       gridColumnStart: '3',
//       gridColumnEnd: '6',
//       gridRowStart: '2',
//       gridRowEnd: '5',
//       alignSelf: 'start',
//       order: ['3', '', 'unset'],
//     },
//     '.image': {
//       width: '100%',
//       height: '100%',
//       objectFit: 'cover',
//     },
//   },
//   imagesContainer: {},
//   textContent: {
//     display: 'grid',
//     gridColumnStart: '1',
//     gridColumnEnd: '3',
//     gridRowStart: '2',
//     gridRowEnd: '5',
//     alignSelf: 'start',
//     padding: ['1rem', '', '3rem 1rem 3rem 0rem', '4rem 1rem 4rem 0rem'],
//     '.title': {
//       order: '2',
//       '::before': {
//         display: 'flex',
//         content: "''",
//         width: '50px',
//         height: '1px',
//         backgroundColor: 'grey',
//         margin: '0.75rem 0rem 1.5rem',
//       },
//     },
//     '.subtitle': {
//       order: '1',
//     },
//     '.body': {
//       order: '3',
//     },
//     '.buttonsContainer': {
//       order: '4',
//       a: {
//         variant: 'buttons.primary',
//       },
//     },
//   },
// }
