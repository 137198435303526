import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Flex, Box, Heading, Text, Image, Button } from '@theme-ui/components'
import axios from 'axios-jsonp'
import jsonpAdapter from 'axios-jsonp'

const Shout = () => {
  const [shoutData, setShoutData] = useState(null)

  useEffect(() => {
    axios({
      url: `https://data.prod.gonation.com/profile/shoutsnew/bzn-ZwpxdubGQEuQvv1q88omCw`,
      adapter: jsonpAdapter,
    }).then((res) => {
      setShoutData(res.data)
    })
  }, [])

  const addHTTP = (url) => {
    var prefix = 'https://'
    if (url.substr(0, prefix.length) !== prefix) {
      return prefix + url
    }
    return url
  }

  const renderCTAs = () => {
    const buttonNames = Object.keys(shoutData.shout.ctas)
    const { ctas } = shoutData.shout
    return buttonNames
      .filter((btn) => btn !== 'cta1' && btn !== 'cta2')
      .map((button) => {
        if (ctas[button].includes('.com')) {
          return (
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={addHTTP(ctas[button])}
              style={{ textDecoration: 'underline' }}
            >
              {button}
            </a>
          )
        }
        return (
          <Link to={ctas[button]}>
            <Button
              sx={{
                padding: ['1rem 4rem', '1rem 4rem', '1rem 4rem'],
                fontSize: [1, 1, 2],
                cursor: 'pointer',
              }}
            >
              {button}
            </Button>
          </Link>
        )
      })
  }

  if (shoutData !== null) {
    const {
      shout: { text, image },
    } = shoutData
    return (
      <Box sx={{ padding: 2, pt: 12, ...titleBlock }}>
        <Flex sx={{ flexWrap: 'wrap' }}>
          <Box sx={{ width: ['100%', '100%', '50%'] }}>
            <Image
              sx={{ width: '100%' }}
              alt='Recent Announcement from Locanda Vecchia'
              src={`${shoutData.imageBaseUrl}/${image.image.cloudinaryId}`}
            ></Image>
          </Box>
          <Box
            sx={{
              width: ['100%', '100%', '50%'],
              padding: 2,
              paddingX: [2, 2, '3rem'],
            }}
          >
            <Heading sx={{ fontSize: [5, 5, 6], fontWeight: 200, mb: 3 }}>
              Recent Shout
            </Heading>
            <Box sx={line}></Box>
            <Text
              as='p'
              sx={{
                color: 'secondary',
                fontFamily: 'subheading',
                fontWeight: 600,
                fontSize: [2, 2, 3],
                maxWidth: '650px',
              }}
            >
              {text}
            </Text>
            <Box sx={{ mt: [4, 5, 9] }}>{renderCTAs()}</Box>
          </Box>
        </Flex>
      </Box>
    )
  }
  return ''
}

export default Shout

const titleBlock = {
  padding: ['3rem 1rem', '', '4rem 2rem 4rem 6rem'],
  '.title': {
    fontSize: ['2rem', '2.5rem', '3rem'],
  },
}

const line = {
  width: '75px',
  height: '2px',
  backgroundColor: 'secondary',
  mb: [6, 7, 8],
}
