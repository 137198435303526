import React from 'react'
import ContentBlockMain from '../components/ContentBlocks/ContentBlockMain'
import Hero from '../components/Heros/Hero'
import TitleBlock from '../components/ContentBlocks/TitleBlock'
import ContentBoxes from '../components/ContentBlocks/ContentBoxes'
import Shout from '../components/Shout/Shout'
const IndexPage = () => {
  return (
    <main>
      <Hero storyId={'a-art-h6ezz16RTDGzkFeA3byB_g'} logoHidden={false} />
      <Shout />
      <TitleBlock
        storyId={'a-art-uvy63BwTQUeot8uxTfjh0Q'}
        sx={styles.titleBlock}
      />
      <ContentBlockMain storyId={'a-art-1a3nVdFrRNGLARgVPoGqOw'} />

      <TitleBlock
        storyId={'a-art-ahRg4lr9RaOYQvnie4TZcQ'}
        sx={styles.titleBlock2}
      />

      <ContentBoxes
        storyIds={[
          'a-art-Z8cD90WSQeaGd79EZRRigw',
          'a-art-vpx7mUHeQOGQ3mf03ofIAQ',
          'a-art-zll6wDURSn28jmJMxPwESg',
          'a-art-JDEp1ak_R3ixcm8UgYvyxw',
        ]}
        sx={styles.contentBoxes}
      />

      <ContentBlockMain
        storyId={'a-art-jeH3aMkhSGW4MCmafoAUFg'}
        sx={styles.ContentBlockMain2}
      />
    </main>
  )
}

export default IndexPage

const styles = {
  titleBlock: {
    padding: ['3rem 1rem', '', '4rem 2rem 4rem 6rem'],
    '.title': {
      fontSize: ['2rem', '2.5rem', '3rem'],
    },
  },

  titleBlock2: {
    padding: ['3rem 1rem', '', '4rem 2rem 5rem 6rem'],
    '.title': {
      fontSize: ['2rem', '2.5rem', '3rem'],
    },
  },
  contentBoxes: {
    marginBottom: '3rem',
  },

  ContentBlockMain2: {
    // gridTemplateRows: 'repeat(2, 1fr)',
    // '.imageContainer1': {
    //   height: '100%',
    // },
    // '.imageContainer1': {
    //   height: '100%',
    // },
    // '.imageContainer3': {
    //   gridColumnStart: '1',
    //   gridColumnEnd: '3',
    //   gridRowStart: '2',
    //   gridRowEnd: '5',
    // },
    // '.content': {
    //   // backgroundColor: '#fef1f1',
    //   padding: ['1rem', '', '5rem 3rem'],
    //   gridColumnStart: '3',
    //   gridColumnEnd: '6',
    //   gridRowStart: '2',
    //   gridRowEnd: '5',
    // },
  },
}
